import React from 'react';
import CatoImg from '../cato-image';
import {IS_MOBILE} from "../../common/consts";
import './style.scss'

class BannerHeadlineTextImage extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true})
        }
    }

    render() {
        const {isMobile} = this.state;

        return (
            <section className="banner-section banner-headline-text-image-wrapper" ref={this.props.containerRef ? this.props.containerRef : null} style={this.props.style}>
                {this.props.headline && <h1>{this.props.headline}</h1>}
                {this.props.text && <p>{this.props.text}</p>}
                {this.props.img && (!isMobile || this.props.showImgMobile) &&
                <div className="image-wrapper">
                    <CatoImg img={this.props.img}/>
                </div>
                }
            </section>
        )
    }
};

export default BannerHeadlineTextImage