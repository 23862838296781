import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import CatoImg from '../cato-image';
import CatoButton from '../cato-button';
import './style.scss';
import YellowGuy from './yellow guy.svg';

const htmlDecode = input => input.replace(/(<([^>]+)>)/ig,"");


const FAQ = ({headline, questions, firstOpen, button}) => {

    const [active, setActive] = useState([]);

    useEffect(() => {
       if(firstOpen) {
           setActive([...active, 'question-0'])
       }
    },[])

    const toggleOpen = (e) => {

        if(active.includes(e.target.id)){
            const newActive = active.filter(i => i !== e.target.id);
            setActive(newActive)
        } else {
            setActive([...active, e.target.id]);
        }

    }

    return (
        <section className="faq-section" >
            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [${questions.map((q) => (`{
                            "@type": "Question",
                            "name": "${q.question}",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "${q.answer ? htmlDecode(q.answer) : ''}"
                            }
                        }`))}]
                    }`}
                </script>
            </Helmet>
            <div className="left">
                <h2 itemProp="name" dangerouslySetInnerHTML={{__html: headline}}/>
                <CatoImg img={YellowGuy} />
            </div>
            <div className="right">
                <ul>
                    {questions.map((q,i) => (
                        <li
                            key={`question-${i}`}
                            className={`${active.includes(`question-${i}`) ? 'active' : ''}`}
                            onClick={toggleOpen}
                            id={`question-${i}`}
                        >
                            <div className="question" style={{pointerEvents: 'none'}}>{q.question}</div>
                            <div className="answer" style={{pointerEvents: 'none'}} dangerouslySetInnerHTML={{__html: q.answer}} />
                        </li>
                    ))}
                </ul>
                {
                    button && button.path && button.label &&
                        <CatoButton
                            className={'orange mid'}
                            path={button.path}
                            label={button.label}
                        />

                }
            </div>
        </section>
    )
}

FAQ.defaultProps = {
    firstOpen: true
}

export default FAQ;