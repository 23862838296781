import React, {Component} from 'react';
import {graphql} from "gatsby";
import Layout from '../components/layout';
import BannerHeadlineTextImage from '../components/banner-headline-text-image';
import MidPageInfoBanner from '../components/mid-page-info-banner';
import JoinNetwork from '../components/join-network';
import {videoSectionMapper, mapCoolInsights, mapStickyBanner, mapGartner, hashRedirect} from '../common/utils';
import queryString from 'query-string';
import {GTag, LANG} from "../common/consts";
import GartnerStrip from '../components/gartner-strip';
import FAQ from '../components/faq';


class CatoCloud extends Component {

    constructor(props) {
        super(props)

        this.banner = React.createRef();
    }

    componentDidMount() {
        const params = queryString.parse(window.location.search);

        if('row' in params){
            const scrollHeight = this.banner.current.offsetTop;
            window.scrollTo(0, scrollHeight)
        }

        if(window.location.hash.substring(1).length) {
            hashRedirect({hash: 'Mobile-Access-Optimization', newHash: 'Secure-Remote-Access'})
        }
    }


    generateMidPageData = (acf) => {

        const rows = acf.middlePageTextImageRow;

        if(typeof window !== 'undefined' && window.location.search) {
            const params = queryString.parse(window.location.search);
            if('row' in params){
                const index = rows.findIndex((item, index, arr) => item.middlePageAnchorId === params.row);
                const tmp = rows[index];
                rows.splice(0, 0, tmp);
            }
        }

        return rows.map((item, i) => {
            const img = item.middlePageRowImage.localFile ? item.middlePageRowImage : null;

            return ({
                bannerImg: img,
                heading: item.middlePageRowHeadline || "",
                text: item.middlePageRowText || "",
                withCarousel: item.resources && item.resources.length > 0,
                anchorID: item.middlePageAnchorId || null,
                link: item.middlePageLink,
                carouselData: item.resources ? item.resources.map((resource) => ({
                    link: resource.resourceFields.resourceLink || "",
                    img: resource.resourceFields.resourceSmallImage ? resource.resourceFields.resourceSmallImage : null,
                    bold: resource.resourceFields.resourceType || "",
                    light: resource.title || ""
                })) : null
            })
        })
    }

    handleDemoEvent = ({label, path}) => GTag({category: 'Request a Demo', action: 'Button Click', label: path})

    render() {
        const {data} = this.props;
        const {wpPage} = data;
        const {headerWithImage, midPageInfoSection, tryUsStrip, videoSection, includeGartner, FAQSection} = wpPage;

        const banners = midPageInfoSection.middlePageTextImageRow ? this.generateMidPageData(midPageInfoSection) : null;

        const bannerData = {
            headline: headerWithImage.headerImageTitle || "",
            text: headerWithImage.headerImageText || "",
            img: headerWithImage.headerImageImage ? headerWithImage.headerImageImage : null
        }

        const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const coolInsights = mapCoolInsights(wpPage);

        const stickyBanner = mapStickyBanner(wpPage);

        const gartner = mapGartner(wpPage);

        return (
            <Layout className="cato-cloud"
                    lang={this.props.pageContext.lang}
                    whiteMenu={true}
                    yoastData={wpPage.seo || null}
                    seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                    translations={this.props.pageContext.translations}
                    wpid={wpPage.databaseId}
                    {...{stickyBanner}}
                    {...{youtube}}
                    {...{coolInsights}}
            >
                <BannerHeadlineTextImage {...bannerData} containerRef={this.banner} style={this.props.pageContext.lang === LANG.JP ? {paddingTop: 'calc(115px + 10px)'} : null}/>
                {banners &&
                banners.map((item, i) => {
                    return (
                        <React.Fragment key={`banners-${i}`}>
                        {i === (parseInt((banners.length / 2),10)) && includeGartner.gartnerInclude && includeGartner.gartnerType === 'accordion' &&
                            <GartnerStrip
                                key={'gartner-accordion'}
                                type={'accordion'}
                            />
                        }
                        <MidPageInfoBanner offWhite={i % 2 === 0} imageFirst={i % 2 !== 0} {...item} key={`cato-cloud-info-banner-${i}`} containerRef={i === 0 ? this.banner : null}/>
                        </React.Fragment>
                        )}
                    )
                }
                <JoinNetwork
                    handleEvent={this.handleDemoEvent}
                    headline={tryUsStrip.tryUsHeadingText ? tryUsStrip.tryUsHeadingText : undefined}
                    buttonText={tryUsStrip.tryUsButtonText ? tryUsStrip.tryUsButtonText : undefined}
                />
                {FAQSection.faq && FAQSection.faq.length &&
                <FAQ
                    headline={FAQSection.faqHeadline}
                    questions={ FAQSection.faq.map(q => ({
                        question: q.faqQuestion,
                        answer: q.faqAnswer
                    }))
                    }
                    button={ FAQSection.faqButton ? {
                        label: FAQSection.faqButton.buttonLabel && FAQSection.faqButton.buttonLabel.length > 0 ? FAQSection.faqButton.buttonLabel : null,
                        path: FAQSection.faqButton.buttonLink && FAQSection.faqButton.buttonLink.length > 0 ? FAQSection.faqButton.buttonLink : null
                    } : null}
                />
                }
            </Layout>
        )
    }
}

export default CatoCloud

export const pageQuery = graphql`
    query catoCloudPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            ...YoastData
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headerWithImage {
                headerImageTitle
                headerImageText
                headerImageImage {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1040) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                        extension
                        publicURL
                    }
                }
            }
            midPageInfoSection {
                middlePageTextImageRow {
                    middlePageRowHeadline
                    middlePageRowText
                    middlePageRowImage {
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                            extension
                            publicURL
                        }
                    }
                    middlePageAnchorId
                    middlePageLink
                    resources {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceType
                                resourceShortDescription
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 504, quality: 100){
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        extension
                                        publicURL
                                    }
                                }
                                resourceLink
                            }
                        }
                    }
                }
            }
            tryUsStrip {
                tryUsHeadingText
                tryUsButtonText
            }
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
            ...FAQSection
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`